<template>
  <div class="homeContent">
    <div class="homeBanner">
      <div class="hb-left">
        <div class="h-banner">
          <div
            @mouseenter="MouseFun(true)"
            @mouseleave="MouseFun(false)"
            class="hb-img"
          >
            <div
              class="imgBox"
              :style="{ left: `-${leftVal}px`, transition: `${ition}s` }"
            >
              <img
                :src="item.imgUrl"
                @click="clickLink(item, index)"
                v-for="(item, index) in pmsCarouselImgList"
                :key="index"
              />
              <!-- 复制第一张放到最后,以实getServiceTypes现无缝无线循环滚动效果 -->
              <img :src="indexImage" alt="" />
            </div>
            <!-- 下方指示点容器 -->
            <div class="instBox">
              <div
                @click="instFun(index)"
                v-for="(item, index) in pmsCarouselImgList.length"
                :key="index"
                :class="['inst', index == imgShow ? 'instActv' : '']"
              ></div>
            </div>
          </div>
        </div>
        <div class="hb-tab">
          <div style="display: flex">
            <div
              style="margin-right: 30px"
              :id="activIndex == 0 ? 'is-active' : ''"
              @click="changeActive(0)"
              class="hb-title"
            >
              服务动态
            </div>
            <div
              :id="activIndex == 1 ? 'is-active' : ''"
              @click="changeActive(1)"
              class="hb-title"
            >
              需求动态
            </div>
          </div>
          <div @click="openServiceHall">
            <span>更多</span>
            <i class="el-icon-d-arrow-right"></i>
          </div>
        </div>
        <div class="hb-imgContent">
          <div v-for="(item, index) in dataList" :key="index" class="ic-box">
            <div @click="openDetail(item)" class="ic-img">
              <img :src="optImage(item.imgUrl)" alt="" />
            </div>
            <div class="ic-textBox">
              <div class="ict-box">
                <div @click="openDetail(item)" class="ictb-title">
                  {{ item.title }}
                </div>
                <div class="ictb-text1">发布日期：{{ item.createTime }}</div>
              </div>
              <div class="ict-box">
                <div>
                  <div style="width: 360; overflow: hidden">
                    <span>{{
                      activIndex == 0 ? "服务品种：" : "品种名称："
                    }}</span>
                    <span class="ictb-text2">{{
                      item.serviceTypeName || "暂无"
                    }}</span>
                  </div>
                  <div>
                    <span>{{
                      activIndex == 0 ? "服务类型：" : "需求类型："
                    }}</span>
                    <span class="ictb-text2">{{
                      item.serviceVarietyName || "暂无"
                    }}</span>
                  </div>
                </div>
                <div class="ict-text3">
                  {{ optPrice(item) }}
                </div>
              </div>
              <div class="ict-box">
                <div>
                  <div style="width: 360; overflow: hidden">
                    <span>{{
                      activIndex == 0 ? "服务区域：" : "需求数量："
                    }}</span>
                    <span class="ictb-text2">{{
                      activIndex == 0 ? optArea(item) : item.quantity
                    }}</span>
                  </div>
                  <div>
                    <span>{{
                      activIndex == 0 ? "服务时间：" : "需求地址："
                    }}</span>
                    <span class="ictb-text2">{{
                      activIndex == 0
                        ? item.startTime + "~" + item.endTime
                        : optArea(item)
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hb-right">
        <div class="h-message">
          <div class="hm-btn1" @click="openRequirements">生产者,发布需求</div>
          <div class="hm-btn2" @click="openPublishService">服务商,发布服务</div>
          <div class="newsListContent">
            <div
              @click="openAgriculate(item)"
              v-for="(item, index) in newsList"
              :key="index"
              class="nl-box"
            >
              <img
                :src="require(`../../assets/image/new${index + 1}.png`)"
                alt=""
              />
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
        <div class="hbr-title">
          <div>农业资讯</div>
          <div style="cursor: pointer" @click="toAgriculte">更多</div>
        </div>

        <div class="hbr-box" v-for="(item, index) in imgList" :key="index">
          <div
            v-if="agariculateIndex != index"
            @mouseenter="mouseEnter(index)"
            @click="goArgiculateDetail(item)"
            class="hbr-text"
          >
            {{ item.title }}
          </div>
          <div
            v-if="agariculateIndex == index"
            class="hrb-imgBox"
            @click="goArgiculateDetail(item)"
          >
            <img :src="item.coverImage" alt="" />
            <div class="hrb-imgtext">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { homeList } from "@/api/serviechall";
import { getToken } from "@/utils/auth";

export default {
  data() {
    return {
      imgList: [],
      leftVal: 0, // 轮播图盒子的偏移值
      flag: true, // 用来节流防止重复点击
      start: null, // 自动执行下一张定的时器
      imgWidth: 1082, // 在这里填写你需要的图片宽度
      intervalTime: "", //轮播过度时长
      ition: 0.8, // 设置轮播图过度时间
      imgShow: 0, // 表示当前显示的图片索引
      newsList: [],
      dataList: [],
      agariculateList: [],
      activIndex: 0,
      agariculateIndex: null,
      demandList: [],
      serviceList: [],
      indexImage: "",
      pmsCarouselImgList: [],
      userTypeArray: [
        { value: "00", label: "系统用户" },
        { value: "01", label: "组织用户" },
        { value: "02", label: "村集体用户" },
        { value: "03", label: "生产主体用户" },
        // { value: "03", label: "生产主体用户" },
        // { value: "03", label: "生产主体用户" },
      ],
    };
  },
  methods: {
    instFun(index) {
      this.ition = 0.8;
      this.leftVal = index * this.imgWidth;
      this.imgShow = index;
    },
    swiper() {
      if (
        this.pmsCarouselImgList.length &&
        this.pmsCarouselImgList.length > 0
      ) {
        this.start = setInterval(() => {
          this.NextFun();
        }, this.intervalTime * 1000);
      }
    },
    NextFun() {
      if (
        this.leftVal ==
        (this.pmsCarouselImgList.length - 1) * this.imgWidth
      ) {
        // 判断显示的图片 是 最后一张时执行
        this.ition = 0.8;
        this.leftVal += this.imgWidth;
        this.imgShow = 0;
        setTimeout(() => {
          this.ition = 0;
          this.leftVal = 0;
        }, this.ition * 1000);
      } else {
        // 判断显示的图片 不是 最后一张时执行
        this.ition = 0.8;
        this.leftVal += this.imgWidth;
        this.imgShow++;
      }
    },
    MouseFun(type) {
      if (!type && !this.start) {
        this.swiper();
      }
      if (type) {
        clearInterval(this.start);
        this.start = null;
      }
    },
    openAgriculate(item) {
      this.$store.dispatch("changeCategory", item.id);
      this.$router.push("/portal/agricultural");
    },
    changeActive(index) {
      this.activIndex = index;
      this.dataList = index ? this.demandList : this.serviceList;
    },
    openServiceHall() {
      this.$router.push("/portal/serviceHall");
    },
    openDetail(item) {
      let id = item.id;
      let query = { id };
      let routeUrl = {};
      if (this.activIndex == 0) {
        routeUrl = this.$router.resolve({
          path: "/portal/serviceHallDetail",
          query,
        });
      } else {
        routeUrl = this.$router.resolve({
          path: "/portal/demandHallDetail",
          query,
        });
      }
      window.open(routeUrl.href, "_blank");
    },
    toAgriculte() {
      this.$router.push("/portal/agricultural");
    },
    goArgiculateDetail(item) {
      let query = { id: item.id };
      let routeUrl = this.$router.resolve({
        path: "/portal/agriculturalDeatil",
        query,
      });
      window.open(routeUrl.href, "_blank");
    },
    mouseEnter(index) {
      let flag = true;
      if (flag) {
        setTimeout(() => {
          this.agariculateIndex = index;
          flag = true;
        }, 1000);
        flag = false;
      }
    },
    homeList(flag) {
      homeList().then((res) => {
        const { code, data } = res;
        if (code === 200) {
          const {
            demandList,
            serviceList,
            cmsCategoryList,
            articleList,
            pmsCarouselImgList,
          } = data;
          this.demandList = demandList;
          this.serviceList = serviceList;
          this.imgList = articleList.map((item) => {
            item.coverImage = this.optImage(item.coverImage);
            return item;
          });
          this.intervalTime = pmsCarouselImgList[0].intervalTime;
          this.pmsCarouselImgList = pmsCarouselImgList.map((item) => {
            item.imgUrl = this.optImage(item.imgUrl);
            return item;
          });
          this.indexImage = this.pmsCarouselImgList[0].imgUrl;
          this.newsList = cmsCategoryList.splice(0, 6);
          this.dataList = this.serviceList;
          if (flag) {
            this.swiper();
          }
        }
      });
    },
    changeDataList(index) {
      this.activIndex = index;
      this.dataList = index === 0 ? this.demandList : this.serviceList;
    },
    optImage(imgUrl) {
      if (imgUrl) {
        return imgUrl.split(",")[0];
      }
      return "";
    },
    optArea(item) {
      const { province, city, county, town, village } = item;
      return (
        (province || "") +
        (city || "") +
        (county || "") +
        (town || "") +
        (village || "")
      );
    },
    optPrice(item) {
      if (item.budgetType == 1) {
        return "面议";
      } else if (this.activIndex == 0) {
        return item.price + item.unitName;
      } else {
        return item.unitName ? item.budget + "/" + item.unitName : item.budget;
      }
    },
    clickLink(item) {
      window.open(item.redirectUrl, "_blank");
    },
    openRequirements() {
      if (getToken()) {
        const userType = this.$store.state.user.userType;
        if (userType == "02" || userType == "03") {
          this.$router.push("/portal/releaseRequirements");
          return;
        } else {
          let userTypeName = "";
          this.userTypeArray.map((item) => {
            if (item.value == userType) {
              userTypeName = item.label;
              return;
            }
          });
          this.$message.warning(
            `您当前是${userTypeName}，只有村集体用户，生产主体用户可以发布需求`
          );
        }
      } else {
        this.$confirm("您当前还未登录要跳转到登录界面吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push("/portal/login");
          })
          .catch(() => {});
      }
    },
    openPublishService() {
      if (getToken()) {
        const userType = this.$store.state.user.userType;
        if (userType == "01") {
          this.$router.push("/portal/PublishingService");
          return;
        } else {
          let userTypeName = "";
          this.userTypeArray.map((item) => {
            if (item.value == userType) {
              userTypeName = item.label;
              return;
            }
          });
          this.$message.warning(
            `您当前是${userTypeName}，只有组织主体用户可以发布服务`
          );
        }
      } else {
        this.$confirm("您当前还未登录要跳转到登录界面吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push("/portal/login");
          })
          .catch(() => {});
      }
    },
  },
  watch: {
    $router() {
      // this.homeList(true);
    },
  },
  created() {
    this.homeList(true);
  },
  beforeDestroy() {
    clearInterval(this.start);
    this.start = null;
  },
};
</script>
<style lang="scss" scoped>
.homeContent {
  width: 100%;
  .homeBanner {
    width: 1396px;
    margin: 20px auto 0;
    display: flex;
    justify-content: space-between;
    .hb-left {
      width: 1082px;
      .h-banner {
        height: 520px;
        padding-bottom: 20px;
        border-bottom: 2px solid #17a763;
        .hb-img {
          width: 100%;
          height: 520px;
          position: relative;
          overflow: hidden;
          cursor: pointer;
          .imgBox {
            position: absolute;
            top: 0px;
            left: 0px;
            min-width: 1082px;
            height: 520px;
            display: flex;
            justify-content: flex-start;
          }
          /* 图片默认样式 */
          .imgBox img {
            flex-shrink: 0;
            width: 1082px;
            height: 520px;
          }
          /* 下方指示器盒子 */
          .instBox {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 10px;
            display: flex;
          }
          /* 小圆点 */
          .inst {
            width: 9px;
            height: 9px;
            background-color: #17a763;
            opacity: 0.5;
            border-radius: 50%;
            margin: 0 3px;
            text-indent: -1111px;
            cursor: pointer;
          }
          .inst:last-child {
            margin-right: 0px;
          }
          .instActv {
            width: 36px;
            opacity: 1;
            border-radius: 50px;
          }
        }
      }
      .hb-tab {
        width: 100%;
        border-bottom: 1px solid #dddddd;
        height: 50px;
        line-height: 50px;
        cursor: pointer;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        box-sizing: border-box;
        font-size: 16px;
        color: #777;
        .hb-title {
          font-size: 18px;
          font-weight: bold;
          color: #333;
          height: 50px;
          padding: 0 10px;
        }
        #is-active {
          background-color: #17a763;
          color: #fff;
        }
      }
      .hb-imgContent {
        margin-top: 20px;
        .ic-box {
          width: 1042px;
          height: 105px;
          /* border-bottom: 1px solid #dddddd; */
          padding: 18px 20px;
          background-color: #fff;
          margin-top: 15px;
          box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.06);
          display: flex;
          justify-content: space-between;
          .ic-img {
            width: 185px;
            height: 104px;
            box-shadow: 2px 4px 5px rgba(0, 0, 0, 50%);
            border-radius: 2px;
            overflow: hidden;
            cursor: pointer;
            img {
              width: 185px;
              height: 104px;
              transition: all 0.8s;
            }
          }
          .ic-img:hover img {
            transform: scale(1.1);
          }
          .ic-textBox {
            width: 820px;
            .ict-box {
              width: 100%;
              display: flex;
              align-items: center;
              font-size: 16px;
              color: #a1a3a6;
              margin-bottom: 10px;
              justify-content: space-between;
              div {
                display: flex;
              }
              .ictb-title {
                cursor: pointer;
                font-size: 20px;
                color: #000;
                width: 600px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              .ictb-title:hover {
                color: #17a763;
              }
              .ictb-text1 {
                font-size: 12px;
                color: #a1a3a6;
              }
              .ictb-text2 {
                color: #000;
                width: 250px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                display: block;
              }
              .ict-text3 {
                font-size: 18px;
                color: #17a763;
              }
            }
          }
        }
      }
    }
    .hb-right {
      width: 305px;
      .h-message {
        width: 100%;
        height: 520px;
        padding-bottom: 20px;
        border-bottom: 2px solid #17a763;
        .hm-btn1 {
          height: 50px;
          width: 237px;
          padding-left: 67px;
          line-height: 51px;
          background: url(../../assets/image/home1.png) no-repeat 23px 50%
            #eba331;
          margin-bottom: 15px;
          border-radius: 5px;
          transition: all 0.2s;
          cursor: pointer;
        }
        .hm-btn2 {
          height: 50px;
          width: 237px;
          padding-left: 67px;
          line-height: 51px;
          background: url(../../assets/image/home2.png) no-repeat 23px 50%
            #36bf4b;
          margin-bottom: 15px;
          border-radius: 5px;
          transition: all 0.2s;
          cursor: pointer;
        }
        .hm-btn1:hover {
          box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.3);
        }
        .hm-btn2:hover {
          box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.3);
        }
        .newsListContent {
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          height: 390px;
          margin-top: 20px;
          .nl-box {
            cursor: pointer;
            width: 145px;
            height: 115px;
            display: flex;
            flex-direction: column;
            border-radius: 6px;
            color: #fc7b6d;
            font-size: 16px;
            margin-top: 3%;
            background-color: #f2f5fc;
            transition: all 0.2s;
            justify-content: center;
            align-items: center;
            padding: 5px;
            box-sizing: border-box;
            text-align: center;
            img {
              width: 40px;
              margin-bottom: 5px;
              z-index: 9;
            }
          }
          .nl-box:hover {
            color: #f2f5fc;
            background-color: #17a763;
            box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.3);
          }
        }
      }
      .hbr-title {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 5px;
        box-sizing: border-box;
        font-size: 16px;
        color: #333;
      }
      .hbr-box {
        width: 100%;
        .hbr-text {
          line-height: 42px;
          padding: 0 13px;
          font-size: 14px;
          color: #2a2a2a;
          border-bottom: 1px solid #ddd;
          height: 42px;
          padding: 0 5px;
          box-sizing: border-box;
          cursor: pointer;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .hrb-imgBox {
          width: 100%;
          position: relative;
          cursor: pointer;
          img {
            width: 100%;
            height: 178px;
          }
          .hrb-imgtext {
            position: absolute;
            bottom: 0;
            height: 30px;
            padding: 0px 13px;
            width: 91%;
            font-size: 14px;
            color: #ffffff;
            background: rgba(0, 0, 0, 0.5);
            line-height: 30px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
            display: block;
          }
        }
      }
    }
  }
}
</style>